<template>
  <div class="container-fluid" id="order-container">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-2"
        >
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="font-weight-bold">Pending Acceptance - Orders</h4>
          </div>
          <div class="create-workform">
            <div
              class="d-flex flex-wrap align-items-center justify-content-between"
            >
              <div class="modal-product-search d-flex">
                <div class="mr-3 position-relative">
                  <search
                    :search="search"
                    :placeholder="'Search'"
                    v-model="search"
                    @searchFilter="handleSearchOrder"
                  >
                  </search>
                  <p class="search-css">Search by number, car vin, car license plate</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-block card-stretch">
              <div class="card-body-2">
                <div
                  class="mb-2 d-flex flex-wrap align-items-center justify-content-between"
                >
                  <h5>
                    Order List

                    <button
                      class="ml-2 btn btn-success btn-sm"
                      @click="refreshClick()"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="white"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M20.944 12.979c-.489 4.509-4.306 8.021-8.944 8.021-2.698 0-5.112-1.194-6.763-3.075l1.245-1.633c1.283 1.645 3.276 2.708 5.518 2.708 3.526 0 6.444-2.624 6.923-6.021h-2.923l4-5.25 4 5.25h-3.056zm-15.864-1.979c.487-3.387 3.4-6 6.92-6 2.237 0 4.228 1.059 5.51 2.698l1.244-1.632c-1.65-1.876-4.061-3.066-6.754-3.066-4.632 0-8.443 3.501-8.941 8h-3.059l4 5.25 4-5.25h-2.92z"
                        />
                      </svg>
                      Refresh
                    </button>
                  </h5>

                  <div class="d-flex flex-wrap mt-2 mobile-align-customer">
                    <div class="sorting-div">
                      <sortingText
                        :sortingData="sortingData"
                        :sorting="sorting"
                        @changeSorting="onChangeSorting"
                      ></sortingText>
                    </div>
                  </div>
                </div>
                <div id="load-spinner">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div class="table-responsive table-zoom" id="order-list">
                  <table class="table data-table table-order mb-0">
                    <thead class="table-color-heading">
                      <tr class="text-light">
                        <th scope="col">
                          <label class="text-muted m-0" for="text1">View</label>
                        </th>
                        <th scope="col">
                          <label class="text-muted m-0" for="text1"
                            >Number</label
                          >
                        </th>
                        <th scope="col" class="dates">
                          <label
                            class="text-muted mb-0"
                            for="validationServer01"
                            >Car</label
                          >
                        </th>
                        <th scope="col" class="dates">
                          <label
                            class="text-muted mb-0"
                            for="validationServer01"
                            >Status</label
                          >
                        </th>
                        <th scope="col">
                          <label
                            class="text-muted mb-0"
                            for="validationServer02"
                            >Schedule At</label
                          >
                        </th>
                        <th scope="col">
                          <label
                            class="text-muted mb-0"
                            for="validationServer02"
                            >Elapsed Days</label
                          >
                        </th>
                        <th scope="col">
                          <label
                            class="text-muted mb-0"
                            for="validationServer02"
                            >Quoted At</label
                          >
                        </th>
                        <th scope="col">
                          <label
                            class="text-muted mb-0"
                            for="validationServer02"
                            >Quoted</label
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-bind:class="
                          order.node.isQuoteRequired &&
                          isLessThan48HourAgo(
                            new Date(order.node.scheduledAt)
                          ) === true &&
                          order.node.workshopQuotedAt == null
                            ? 'red-highlight white-space-no-wrap'
                            : order.node.approvedQuotation
                            ? 'green-highlight white-space-no-wrap'
                            : 'white-space-no-wrap transition-row'
                        "
                        v-for="(order, index) in orderList"
                        :key="index"
                      >
                        <td>
                          <div
                            class="d-flex justify-content-around align-items-center"
                          >
                            <router-link
                              :to="'order/' + order.node.number"
                              class="click-area-extend"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="text-secondary"
                                width="20"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                />
                              </svg>
                            </router-link>
                          </div>
                        </td>
                        <td class="table-font">
                          <div title="Click To Copy">
                            <div
                              @click.prevent="
                                copyIdToClipboard(order.node.number)
                              "
                              class="customer-phone"
                            >
                              {{ order.node.number }}
                            </div>
                          </div>
                        </td>

                        <td class="table-font">
                          <div>
                            <div>
                              {{
                                order.node.car.model.make.name +
                                " " +
                                order.node.car.model.name
                              }}
                            </div>
                            <div v-if="order.node.car.licensePlate">
                              {{ order.node.car.licensePlate }}
                            </div>
                          </div>
                        </td>

                        <!-- STATUS -->
                        <td
                          v-if="order.node.status == 'SCHEDULED'"
                          class="mb-0 text-warning table-font font-weight-bold"
                        >
                          {{ order.node.status }}
                        </td>
                        <td
                          v-if="order.node.status == 'CANCELLED'"
                          class="mb-0 text-danger table-font font-weight-bold"
                        >
                          {{ order.node.status }}
                        </td>
                        <td
                          v-if="order.node.status == 'FULFILLED'"
                          class="mb-0 text-success table-font font-weight-bold"
                        >
                          {{ order.node.status }}
                        </td>
                        <td class="table-font">
                          <div>
                            <div>
                              {{ order.node.scheduledAt | dateDayFormat }}
                            </div>

                            <div>
                              {{ order.node.scheduledAt | timeFormat }}
                            </div>
                          </div>
                        </td>

                        <td class="table-font">
                          <div class="data-content">
                            {{
                              new Date(order.node.scheduledAt).getTime() >
                              new Date().getTime()
                                ? 0
                                : Math.ceil(
                                    (new Date().getTime() -
                                      new Date(
                                        order.node.scheduledAt
                                      ).getTime()) /
                                      (1000 * 3600 * 24) -
                                      1
                                  )
                            }}
                          </div>
                        </td>
                        <td class="table-font">
                          <div v-if="order.node.workshopQuotedAt">
                            <div>
                              {{ order.node.workshopQuotedAt | dateDayFormat }}
                            </div>

                            <div>
                              {{ order.node.workshopQuotedAt | timeFormat }}
                            </div>
                          </div>
                          <div v-else>-</div>
                        </td>
                        <td
                          v-if="order.node.workshopQuotedAt"
                          style="text-align: center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="green"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z"
                            />
                          </svg>
                        </td>
                        <td
                          class="table-font"
                          style="text-align: center"
                          v-else
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="red"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"
                            />
                          </svg>
                        </td>
                      </tr>
                      <tr v-if="totalRows === 0">
                        <td colspan="9" align="center">No data found</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row p-3" id="page-bottom">
                <div class="col-sm-12 col-md-6 d-flex align-items-center">
                  <div>
                    Total Orders: <b>{{ totalRows }}</b>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 d-flex justify-content-end">
                  <pagination
                    :currentPage="currentPage"
                    :perPage="perPage"
                    :totalRows="totalRows"
                    @changePage="handlePageChange"
                  ></pagination>
                </div>
              </div>
              <div class="row p-3">
                <div class="col-md-12">
                  <svg
                    width="18"
                    height="18"
                    fill="#ff00003b"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    stroke-linejoin="round"
                    stroke-miterlimit="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1z"
                      fill-rule="nonzero"
                    />
                  </svg>
                  -
                  <span style="font-weight: 600">
                    Quote is required but not uploaded for more than 48hrs
                  </span>
                </div>
                <div class="col-md-12">
                  <svg
                    width="18"
                    height="18"
                    fill="#4cd74c8f"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    stroke-linejoin="round"
                    stroke-miterlimit="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1z"
                      fill-rule="nonzero"
                    />
                  </svg>
                  -
                  <span style="font-weight: 600"> Approved Quotation </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_ORDERS_LIST } from "../../../graphql/query";
import pagination from "../../../components/pagination/pagination.vue";
import search from "../../../components/filters/search.vue";
import sortingText from "../../../components/filters/sorting.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Invoice",
  components: { pagination, search, sortingText },
  data() {
    return {
      showFilters: false,
      orderList: [],
      sortingData: [
        { id: "SCHEDULED_AT - ASC", name: "Scheduled At - Asc." },
        { id: "SCHEDULED_AT - DESC", name: "Scheduled At - Desc." },
        { id: "WORKSHOP_QUOTED_AT - ASC", name: "Quoted At - Asc." },
        { id: "WORKSHOP_QUOTED_AT - DESC", name: "Quoted At - Desc." },
      ],
      sorting: { id: "SCHEDULED_AT - DESC", name: "Scheduled At - Desc." },
      // sorting: 'SCHEDULED_AT - DESC',
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
      after: null,
      before: null,
      hasNextPage: true,
      hasPreviousPage: false,
      last: null,
      first: null,
      direction: "",
      field: "",

      search: null,

      resetSvg: require("../../../assets/images/svg/reset.svg"),

      borderColor: false,
      countFilter: 0,
      customerSearch: null,
    };
  },
  computed: {
    ...mapGetters({
      stateSearch: "customerSearch",
    }),
  },
  mounted() {
    if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      localStorage.removeItem("customer-phone");
      location.reload();
    }
  },

  async created() {
    (this.direction = "DESC"), (this.field = "SCHEDULED_AT");
    this.countFilter = 0;
    var value = JSON.parse(localStorage.getItem("vuex"));
    value = value.orderFilters;
    value.sort_by = this.field + " - " + this.direction;

    if (localStorage.getItem("vuex") !== null) {
      this.first = this.perPage;
      this.getOrderList();
    } else {
      this.countFilter = 0;
      this.first = this.perPage;
      this.getOrderList();
    }
  },

  methods: {
    ...mapActions({
      orderFilterChange: "orderFilterAction",
    }),
    refreshClick() {
      this.first = this.perPage;
      this.last = null;
      this.before = null;
      this.after = null;
      this.currentPage = 1;
      this.getOrderList();
    },

    handlePageChange(event) {
      if (event > this.currentPage && this.hasNextPage) {
        this.currentPage = event;
        this.before = null;
        this.last = null;
        this.first = this.perPage;
        this.after;
        this.getOrderList();
      } else if (event < this.currentPage && this.hasPreviousPage) {
        this.currentPage = event;
        this.after = null;
        this.before;
        this.last = this.perPage;
        this.first = null;
        this.getOrderList();
      }
    },
    onChangeSorting(event) {
      if (event.id !== null) {
        var res = event.id.split("-");
        this.field = res[0].replaceAll(" ", "");
        this.direction = res[1].replaceAll(" ", "");
        this.before = null;
        this.after = null;
        this.currentPage = 1;
        this.first = this.perPage;
        this.last = null;
        this.orderFilters.sort_by = this.field + " - " + this.direction;
        this.changeOrderFilters(this.orderFilters);
        this.getOrderList();
      } else {
        (this.direction = "DESC"), (this.field = "SCHEDULED_AT");
        this.orderFilters.sort_by = this.field + " - " + this.direction;
        this.changeOrderFilters(this.orderFilters);
        this.before = null;
        this.after = null;
        this.first = this.perPage;
        this.last = null;
        this.currentPage = 1;
        this.getOrderList();
      }
    },
    async getOrderList() {
      // document.getElementById('order-list').style.display = 'none';
      // document.getElementById('page-bottom').style.display = 'none';
      // document.getElementById('load-spinner').style.display = 'flex';
      await this.$apollo
        .query({
          query: GET_ORDERS_LIST,
          variables: {
            direction: this.direction,
            field: this.field,
            first: this.first,
            after: this.after,
            before: this.before,
            last: this.last,
            status: this.status,
            gte: this.gte ? this.gte : null,
            lte: this.lte ? this.lte : null,
            isPendingAcceptance: true,
            isQuoted:
              this.isQuoted == null
                ? null
                : this.isQuoted == "True"
                ? true
                : false,
            isQuoteRequired:
              this.isQuoteRequired == null
                ? null
                : this.isQuoteRequired == "True"
                ? true
                : false,
            hasApprovedQuotation:
              this.hasApprovedQuotation == null
                ? null
                : this.hasApprovedQuotation == "True"
                ? true
                : false,
            search: this.search ? this.search.replace(/\s/g, "") : null,
            gte_quoted: this.gte_quoted ? this.gte_quoted : null,
            lte_quoted: this.lte_quoted ? this.lte_quoted : null,
            workshop: this.workshop ? this.workshop.id : null,
          },
        })
        .then((data) => {
          document.getElementById("load-spinner").style.display = "none";
          document.getElementById("order-list").style.display = "block";
          document.getElementById("page-bottom").style.display = "flex";
          this.orderList = data.data.workshopOrders.edges;
          this.totalRows = data.data.workshopOrders.totalCount;
          this.after = data.data.workshopOrders.pageInfo.endCursor;
          this.before = data.data.workshopOrders.pageInfo.startCursor;
          this.hasNextPage = data.data.workshopOrders.pageInfo.hasNextPage;
          this.hasPreviousPage =
            data.data.workshopOrders.pageInfo.hasPreviousPage;
        });
    },

    handleSearchOrder(event) {
      this.search = event;
      this.after = null;
      this.before = null;
      this.last = null;
      this.first = this.perPage;
      this.currentPage = 1;
      this.getOrderList();
    },

    copyIdToClipboard(id) {
      var input_temp = document.createElement("textarea");
      input_temp.setAttribute("class", "text-area-clipboard");
      input_temp.innerHTML = id;
      document.body.appendChild(input_temp);
      input_temp.select();
      input_temp.setSelectionRange(0, 99999);
      document.execCommand("copy");

      var message = "Copied";
      this.$bvToast.toast(id + " " + `${message}`, {
        toaster: "b-toaster-top-center",
        variant: "success",
        solid: true,
        toastClass: "text-center toast-box toast-style",
      });
    },
    isLessThan48HourAgo(date) {
      // :point_down:️   hour  min  sec  milliseconds
      const twentyFourHrInMs = 48 * 60 * 60 * 1000;
      const twentyFourHoursAgo = Date.now() - twentyFourHrInMs;
      return date < twentyFourHoursAgo && date <= Date.now();
    },
  },
};
</script>


<style>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(50px);
  opacity: 0;
}

.rotate {
  transform: rotate(180deg);
}

.table-font {
  font-size: 0.9rem;
}

.filter-label-heading {
  font-weight: 600;
  /* font-size: 0.9rem; */
  font-size: 12px;
  text-transform: capitalize !important;
}
table.table-order {
  text-align: center;
}
.filter-btn {
  font-size: 1.1rem !important;
}

.multOptions .multiselect__tags {
  /* height: 72px; */
  height: 55px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  /* display: inline-table; */
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.singleOptions .multiselect__tags {
  min-height: 47px;
}

.customMultiSelect-div {
  max-width: 22.2%;
  flex: 0 0 22.2%;
}
.click-area-extend {
  padding: 0.25rem 1rem 0.25rem 1rem;
  cursor: pointer;
}
.toast-style {
  font-size: 1.2rem;
  padding: 1rem;
}
.customer-phone:hover {
  color: blue;
  cursor: pointer;
}
.text-area-clipboard {
  border: none;
  color: white;
  height: 0;
  width: 0;
}
.transition-row {
  transition: transform 0.1s;
}
.transition-row:hover {
  background-color: rgb(245, 245, 245);
  /* transform: scale(0.99); */
}
.table-zoom {
  zoom: 90%;
}
.table th,
.table td {
  padding: 12px 12px;
}

.b-toast-success.b-toast-solid .toast {
  padding: 10px;
  width: 200px;
  margin: 0 auto;
}

.white-space {
  overflow: auto;
  white-space: nowrap;
}
.card-body-2 {
  padding: 10px 20px 20px 20px;
}
.singleOptions .multiselect__tags {
  min-height: 10px !important;
}
.multiselect {
  min-height: 10px !important;
}
#order-container .singleOptions .multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  padding-left: 5px;
  min-height: 12px !important;
  line-height: 12px !important;
  margin-bottom: 7px;
}

#order-container .borderColorFilter {
  /* background-color: gray; */
  border: 2px solid #9a18ffd1 !important;
  border-radius: 5px;
}

.singleOptions .form-control-sm {
  line-height: 24px;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 13px !important;
}
.multiselect__input,
.multiselect__single {
  min-height: 12px !important;
  line-height: 14px !important;
}
.multiselect__select {
  height: 31px !important;
}
.filter-cross-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0 0.75rem 0 0.75em;
  border-radius: 0.25rem;
}

.b-form-btn-label-control.form-control > .form-control.form-control-sm {
  overflow: auto !important;
  white-space: nowrap !important;
}
.reset-btn-padding {
  padding: 0.4rem;
}

.reset-btn-padding-onlist {
  padding: 0.4rem;
}

.assigned-to-custom {
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 40%;
  right: 20%;
  z-index: 1;
  transform: translate(0%, -50%);
}

.notify .badge {
  background: radial-gradient(5px -9px, circle, white 8%, #ed2324 26px);
  background: -moz-radial-gradient(5px -9px, circle, white 8%, #ed2324 26px);
  background: -ms-radial-gradient(5px -9px, circle, white 8%, #ed2324 26px);
  background: -o-radial-gradient(5px -9px, circle, white 8%, #ed2324 26px);
  background: -webkit-radial-gradient(5px -9px, circle, white 8%, #ed2324 26px);
  background-color: #ed2324;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 5% 5%;
  float: right;
  display: block;
  position: relative;
  top: -16px;
  left: 12px;
}

.message-count {
  position: relative;
  display: block;
  font: bold 14px/13px Helvetica, Verdana, Tahoma;
  text-align: center;
  margin: 0;
  top: 2px;
}
.red-highlight {
  background: #ff00003b;
}
.red-highlight:hover {
  background: #ff00004a;
}
.green-highlight {
  background: #4cd74c8f;
}
.green-highlight:hover {
  background: #4cd74cbd;
}
#order-list {
  display: none;
}
#page-bottom {
  display: none;
}

#load-spinner {
  justify-content: center;
  display: flex;
}
@media only screen and (max-width: 768px) {
  .table th,
  .table td {
    padding: 5px 5px;
  }
  .customMultiSelect-div {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 768px) {
  .col-half-offset {
    margin-left: 4.166666667%;
  }
}

/* @media only screen and (max-width: 470px) {
  .mobile-align-customer {
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }
} */

@media only screen and (max-width: 417px) {
  .sorting-div {
    margin-bottom: 1rem;
  }
  .filter-div {
    margin-left: 0rem !important;
  }
}

/* @media only screen and (max-width:407px){
  .filters-div{
    margin-top:8px;
  }
} */
</style>


